/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: 'PTSansCaption-Regular';
  src: url('./assets/fonts/PTSansCaption-Regular.ttf');
}

@font-face {
  font-family: 'PTSansCaption-Bold';
  src: url('./assets/fonts/PTSansCaption-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
}

@import "./assets/variables.scss";


body {
  margin: 0;
}

// Font Awesome
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

// MDB SCSS
@import "node_modules/mdb-angular-ui-kit/assets/scss/mdb.scss";

.mat-expansion-indicator::after {
  color: #ffff !important;
  padding: 5px !important;
}

.mat-expansion-indicator {
  margin-top: 5px !important;
  height: 36px;
}

.mat-expansion-panel {
  margin: 0px !important;
  ;
}

.mat-expansion-panel-header:hover {
  background: inherit !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

// homepage expansion
.third-screen {
  .mat-expansion-panel-body {
    height: 171px;
  }
}

.feedbacks-container {
  .slick-list {
    padding-bottom: 48px !important;
  }
}

@media (max-width: 767.98px) {
  .third-screen {
    .first-box {
      .mat-expansion-panel-body {
        height: 166px;
      }
    }

    .third-box {
      .mat-expansion-panel-body {
        height: 186px;
      }
    }

    .mat-expansion-panel-body {
      height: 166px;
    }
  }

  //test component
  .mat-radio-label {
    white-space: normal !important;
  }
}

// homepage expansion

@media (max-width: 767.98px) {
  .mat-dialog-container {
    padding: 0px !important;
  }
}

.mat-dialog-container {
  border-radius: 14px !important;
}

// Homepage carousel
@media (min-width:768px) and (max-width:1022.98px) {

  button.carousel-control-next.ng-star-inserted,
  button.carousel-control-prev.ng-star-inserted {
    height: 158px;
  }
}

.carousel-indicators {
  bottom: -40px !important;

  button {
    border-top: none;
    border-bottom: none;
    border-radius: 20px;
    width: 6px;
    height: 6px;
    background-color: #1C2978;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: #1C2978 !important;
}

// Homepage carousel 


.mat-form-field-wrapper {
  padding-bottom: 16px !important;
  margin: 0 !important;
}